<!--
{ id: '', code: '', desc: '전체' },
{ id: 'N', code: 'N', desc: '접수대기' },
{ id: 'C', code: 'C', desc: '반려' },
{ id: 'M', code: 'M', desc: '재고지급' },
{ id: 'S', code: 'S', desc: '접수완료' },
{ id: 'F', code: 'F', desc: '구매완료' },
-->
<template>
  <PageWithLayout>
    <PdfConverter ref="PdfConverter" :detailData="detailData" @sendPaymentCb="sendPaymentCb">
      <div class="section_comm">
        <div class="head_section">
          <h2 class="tit_section">
            구매요청 <span class="txt_view">{{ model.requestCid }}</span>
          </h2>
        </div>
        <div class="body_section">
          <TableViewWithTitle title="구매요청 내용">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col style="width: 428px" />
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>프로젝트 코드</th>
                <td>
                  {{ model.projectCode }}
                </td>
                <th>프로젝트 명</th>
                <td v-html="model.projectName"></td>
              </tr>
              <tr>
                <th>구매요청명</th>

                <td colspan="3" v-html="model.title"></td>
              </tr>
              <tr>
                <th>내용</th>
                <td class="td_text align_top" style="height: 200px" colspan="3" v-html="contents" />
              </tr>
            </template>
          </TableViewWithTitle>
          <TableViewWithTitle title="구매구분">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <th>구매구분</th>
              <td>
                {{ model.assetTypeName }}
              </td>
            </template>
          </TableViewWithTitle>

          <template>
            <TableViewWithTitle title="기안 금액">
              <template v-slot:colgroup>
                <col style="width: 40px" />
                <col />
                <col style="width: 110px" />
                <col style="width: 110px" />
                <col style="width: 120px" />
                <col style="width: 80px" />
                <col style="width: 120px" />
                <col style="width: 120px" />
                <col style="width: 480px" />
              </template>
              <template v-slot:header>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">품명</th>
                  <th scope="col">품목코드</th>
                  <th scope="col">자산코드</th>
                  <th scope="col">단위</th>
                  <th scope="col">수량</th>
                  <th scope="col">단가</th>
                  <th scope="col">금액</th>
                  <th scope="col">품목상세</th>
                </tr>
              </template>

              <template v-slot:body>
                <tr v-for="(item, index) in model.purchaseGoodsList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.itemName | nullToDash }}</td>
                  <td>
                    <button
                      :class="['link_subject']"
                      @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${item.itemCode}`)"
                    >
                      {{ item.displayCode }}
                    </button>
                  </td>
                  <td>{{ item.assetsCode | nullToDash }}</td>
                  <td>
                    {{ item.unitValue | nullToDash }}
                  </td>
                  <td class="align_right">{{ item.quantity | currency | nullToDash }}</td>
                  <td class="align_right">{{ item.price | currency }}</td>
                  <td class="align_right">{{ item.totalPrice | currency }}</td>
                  <td :data-ellip="item.note">
                    {{ item.note | nullToDash }}
                  </td>
                </tr>
              </template>
              <template v-slot:foot>
                <div class="tbl_foot">
                  <div class="area_total">
                    <dl>
                      <dt>
                        총 기안금액
                        <!-- <span class="txt_info">(부가세 제외)</span> -->
                      </dt>
                      <dd>{{ model.totalPrice | currency }}</dd>
                    </dl>
                  </div>
                </div>
              </template>
            </TableViewWithTitle>
          </template>

          <TableViewWithTitle title="구매요청 정보">
            <template v-slot:body>
              <tr>
                <th>진행상태</th>
                <td colspan="3">
                  {{ model.statusCodeName }}
                </td>
              </tr>
              <tr>
                <th>구매 요청번호</th>
                <td>{{ model.requestCid }}</td>
                <th>구매 요청등록일</th>
                <td>{{ model.requestDate | dateStringFormat }}</td>
              </tr>
              <tr>
                <th>구매 요청자</th>
                <td>{{ `${model.requesterName} (${model.requesterId})` }}</td>
                <th>요청부서</th>
                <td>{{ model.requestDeptName | departmentText }}</td>
              </tr>
              <tr>
                <th>구매담당자</th>
                <td colspan="3" class="td_text align_mid">
                  {{ buyerListText }}
                  <!-- <span v-for="(buyer, index) in model.buyerList" :key="buyer.loginId">
                  {{ index > 0 ? ', ' : '' }}{{ `${buyer.loginId}(${buyer.name})` }}
                </span> -->
                </td>
              </tr>
              <tr>
                <th>계약 기간</th>
                <td colspan="3">
                  {{ dateRange | nullToDash }}
                </td>
              </tr>
              <tr>
                <th>대금지급 방법 및 조건</th>
                <td colspan="3">
                  {{ model.paymentName }}
                </td>
              </tr>
              <template v-if="model.payment == 'D'">
                <tr>
                  <th>선금비율 (%)</th>
                  <td>{{ model.advanceRate | nullToDash }}</td>

                  <th>선금</th>
                  <td>
                    {{ model.advance | currency | nullToDash }}
                  </td>
                </tr>
                <tr>
                  <th>중도금비율 (%)</th>
                  <td>{{ model.interimRate | nullToDash }}</td>

                  <th>중도금</th>
                  <td>
                    {{ model.interim | currency | nullToDash }}
                  </td>
                </tr>
                <tr>
                  <th>잔금비율 (%)</th>
                  <td>{{ model.balanceRate | nullToDash }}</td>

                  <th>잔금</th>
                  <td>
                    {{ model.balance | currency | nullToDash }}
                  </td>
                </tr>
              </template>
              <tr>
                <th>첨부파일</th>
                <td colspan="3">
                  <FileView :dataList="model.attachFileList || []" />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
          <template>
            <TableViewWithTitle v-if="model.status === 'C'" title="의견정보">
              <template v-slot:colgroup>
                <col style="width: 160px" />
                <col />
                <col style="width: 180px" />
              </template>
              <template v-slot:header>
                <tr>
                  <th scope="col">구매담당자</th>
                  <th scope="col">의견</th>
                  <th scope="col">일시</th>
                </tr>
              </template>
              <template v-slot:body>
                <tr v-for="item in agreeHistory" :key="item.agreeNum">
                  <td>{{ `${item.name} (${item.loginId})` }}</td>
                  <td class="td_preline">{{ item.disagreeReason | nullToDash }}</td>
                  <td>{{ item.agreeDate | dateTimeStringFormat }}</td>
                </tr>
              </template>
            </TableViewWithTitle>
          </template>

          <TableViewWithTitle v-if="$isAdmin && model.status === 'N'" title="반려의견">
            <template v-slot:body>
              <tr>
                <th>반려의견<span class="ico_purchase ico_star">필수항목</span></th>
                <td colspan="3">
                  <Textarea
                    style="height: 120px"
                    placeholder="반려의견를 입력하세요."
                    :maxLength="200"
                    :value.sync="disagreeReason"
                  />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>
      </div>
      <Sticky>
        <div class="area_left">
          <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">
            목록
          </button>

          <template v-if="model.status === 'N' && !ingPayment">
            <button class="btn_primary btn_large" @click="onClickDraft">전자결재</button>
          </template>
          <template v-else>
            <button
              class="btn_primary btn_large"
              :disabled="!isMine || model.status === 'C'"
              @click="
                () => {
                  $router.push(`${computedPaymentPath}/${docNo}`);
                }
              "
            >
              전자결재 {{ docStatusName ? `- ${docStatusName}` : "" }}
            </button>
          </template>
        </div>
        <div class="area_right">
          <template v-if="$isUser || $isInspect">
            <button
              v-if="model.status === 'S' || model.status === 'C' || model.status === 'F'"
              type="button"
              class="btn_tertiary btn_large"
              @click="onClickRenewalPurchase"
            >
              재구매요청
            </button>
            <template v-if="(model.status === 'N' && !ingPayment) || model.status === 'C'">
              <button
                v-if="model.status === 'N'"
                type="button"
                class="btn_primary btn_large"
                @click="onClickModify"
              >
                수정
              </button>
            </template>
          </template>
          <template v-else>
            <!-- 어드민 일때 -->
            <template v-if="model.status === 'S'">
              <template v-if="!inProgress">
                <button
                  class="btn_primary btn_large"
                  @click="onClickMovePage($routerPath.ESTIMATE_WRITE)"
                >
                  견적등록
                </button>
                <button type="button" class="btn_primary btn_large" @click="onClickAnnounceMove">
                  공고등록
                </button>
                <button
                  class="btn_primary btn_large"
                  @click="onClickMovePage($routerPath.CONTRACT_WRITE)"
                >
                  계약등록
                </button>
                <button type="button" class="btn_primary btn_large" @click="onClickOrderMove">
                  발주등록
                </button>
              </template>
              <template v-else>
                <span class="btn_fourthly btn_large" style="margin-right: 5px">
                  계약/발주 진행중
                </span>
                <button
                  type="button"
                  class="btn_tertiary btn_large"
                  @click="onClickRenewalPurchase"
                >
                  재구매요청
                </button>
              </template>
            </template>
            <template v-else>
              <button
                v-if="model.status === 'S' || model.status === 'C' || model.status === 'F'"
                type="button"
                class="btn_tertiary btn_large"
                @click="onClickRenewalPurchase"
              >
                재구매요청
              </button>
              <template v-if="model.status === 'N' || model.status === 'M' || model.status === 'S'">
                <button
                  v-if="model.status !== 'C'"
                  class="btn_fifthly btn_large"
                  @click="onClickConfirm('C')"
                >
                  반려
                </button>
                <button
                  v-if="model.status !== 'M'"
                  class="btn_primary btn_large"
                  @click="onClickConfirm('M')"
                >
                  재고지급
                </button>
                <button
                  v-if="model.status !== 'S'"
                  class="btn_primary btn_large"
                  @click="onClickConfirm('S')"
                >
                  접수완료
                </button>
                <button class="btn_primary btn_large" @click="onClickModify">수정</button>
              </template>
            </template>
          </template>
        </div>
      </Sticky>
    </PdfConverter>
    <template v-slot:popup>
      <AlertPopup
        v-if="isConfirmReject"
        alertText="반려 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="saveStatusUpdate"
      />
      <AlertPopup
        v-if="isConfirmPayment"
        alertText="재고지급 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="saveStatusUpdate"
      />
      <AlertPopup
        v-if="isConfirmRegister"
        alertText="접수완료 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="saveStatusUpdate"
      />
      <AlertPopup
        v-if="isConfirmCancel"
        alertText="구매요청을 취소 하시겠습니까? <br />접수대기 상태로 돌아갑니다."
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="saveStatusCancel"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import FileView from "@/components/common/file/FileView";
import Textarea from "@/components/common/textarea/Textarea";
import Sticky from "@/components/layout/content/Sticky.vue";
import PdfConverter from "@/components/shared/PdfConverter";

import PageMixin from "@/mixins/PageMixin";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import purchaseModel from "./purchaseModel";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { escapeRegExp } from "@/utils/spUtils";
import { getDateString, dateToString } from "@/utils/dateUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "PurchaseView",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    Textarea,
    FileView,
    PdfConverter,
    Sticky,
    AlertPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      model: new purchaseModel(),

      docNo: "", //전자결재 문서
      docStatus: "",
      docStatusName: "",

      updateStatusCode: "N",

      isConfirmReject: false,
      isConfirmPayment: false,
      isConfirmRegister: false,
      isConfirmCancel: false,
      agreeHistory: [],
      disagreeReason: "",
      detailData: {
        refPk: "",
        reqPath: "",
        menuId: "",
        reqTitle: "",
      },
      inProgress: "",
    };
  },
  computed: {
    computePayment() {
      this.model.paymentList.forEach((el) => {
        if (el.code === this.model.payment) {
          return el.name;
        }
      });
    },
    dateRange() {
      const contractStartDate = dateToString(this.model.dates[0]);
      const contractEndDate = dateToString(this.model.dates[1]);
      if (!contractStartDate || !contractEndDate) return "";

      return `${getDateString(contractStartDate)} ~ ${getDateString(contractEndDate)}`;
    },
    loginUserName() {
      const userData = LocalStorageManager.shared.getUserData();
      return userData.username;
    },
    buyerListText() {
      const { buyerList } = this.model;

      if (!buyerList) return "";

      const arr = buyerList.map((item) => this.$options.filters.idWithNameByObj(item));
      return arr.join(", ");
    },
    contents() {
      if (!this.model.contents) return;
      let target = this.model.contents;

      return escapeRegExp(target);
    },
    computedPaymentPath() {
      const { docStatus } = this;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    isEqualDept() {
      const { requestDept } = LocalStorageManager.shared.getUserData();
      const { deptCode } = this.model;
      return requestDept === deptCode;
    },
    isMine() {
      const { empNo } = LocalStorageManager.shared.getUserData();
      const { requesterEmpNo } = this.model;
      return empNo === requesterEmpNo;
    },
    ingPayment() {
      const { docStatus, docStatusName, docNo } = this;

      return docNo ? true : false;
    },
  },
  created() {
    const { id } = this.$route.params;
    this.model.requestCid = id;
    this.getData(id);
  },
  beforeMount() {
    const { id } = this.$route.params;
    if (!id) {
      this.$router.push(this.$routerPath.HOME);
      return;
    }
  },
  methods: {
    async getData() {
      const path = `${this.$apiPath.ST_PURCHASE_REQUEST}/${this.model.requestCid}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (!data) {
        this.alert(text);
        return;
      }
      this.docNo = data.aprvlDocInfo?.docNo || "";
      this.docStatus = data.aprvlDocInfo?.docStatus || "";
      this.docStatusName = data.aprvlDocInfo?.docStatusName || "";
      this.model.setData(data);
      this.agreeHistory = data.agreeHistory || [];
      this.detailData = {
        refPk: "",
        reqPath: this.$route.path,
        menuId: this.$route.path.split("/")[1],
        reqTitle: this.model.title || "-",
      };
      this.inProgress = data.inProgress;
    },
    async saveStatusUpdate() {
      const path = `${this.$apiPath.ST_PURCHASE_REQUEST}/${this.updateStatusCode}/update/${this.model.requestCid}`;
      const obj = {
        disagreeReason: this.disagreeReason,
      };

      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.model.status = data.status;
      this.onClickCloseAlertComfirm();
      this.getData(this.model.requestCid);
      this.disagreeReason = "";
    },
    async saveStatusCancel() {
      const path = `${this.$apiPath.ST_PURCHASE_REQUEST}/cancel/${this.model.requestCid}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code === "200") {
        this.model.status = data.status;
        this.onClickCloseAlertComfirm();

        this.getData(this.model.requestCid);
      }
      this.onClickCloseAlertComfirm();
    },
    onClickConfirm(status) {
      let key = status;
      switch (key) {
        case "C":
          if (!this.disagreeReason) {
            this.alert("의견을 입력해주세요");
            return;
          }
          this.isConfirmReject = true;
          break;
        case "M":
          this.isConfirmPayment = true;
          break;
        case "S":
          this.isConfirmRegister = true;
          break;
        default:
          break;
      }
      this.updateStatusCode = key;
    },
    onClickCancel() {
      this.isConfirmCancel = true;
    },
    onClickCloseAlertComfirm() {
      this.isConfirmReject = false;
      this.isConfirmPayment = false;
      this.isConfirmRegister = false;
      this.isConfirmCancel = false;
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.PURCHASE_LIST,
        query: this.$route.query,
      });
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.PURCHASE_MODIFY}/${this.model.requestCid}`,
      });
    },
    onClickMovePage(routerName) {
      const { requestCid } = this.model;
      const obj = {
        type: "purchase",
        requestCid,
      };

      this.$router.push({
        name: routerName,
        params: obj,
      });
    },

    sendPaymentCb(result) {
      const { code, data, text } = result;
      if (code === "200") {
        this.getData(this.model.requestCid).then(() => {
          const params = {
            text: "전자결재문서가 등록되었습니다.<br />전자결재를 진행하시겠습니까?",
            onClickY: () => {
              this.$router.push({
                path: `${this.computedPaymentPath}/${this.docNo}`,
              });
            },
          };
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
        });
      }
    },
    onClickDraft() {
      const params = {
        text: "전자결재문서를 생성하시겠습니까?<br />전자결재 진행중에는 내용을 수정할수 없습니다.",
        onClickY: () => {
          this.saveDraft();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async saveDraft() {
      const {
        requestCid,
        title,
        assetType,
        requesterEmpNo,
        purchaseGoodsList,
        purchasePriceList,
        projectCode,
        projectName,
      } = this.model;

      const obj = {
        requestCid,
        title,
        assetType,
        regEmpNo: requesterEmpNo,
        draftEmpNo: requesterEmpNo,
        purchaseGoodsList,
        purchasePriceList,
        projectCode,
        projectName,
        contents: "",
        attachFileList: [],
        useYn: false,
      };
      const result = await ApiService.shared
        .post(this.$apiPath.ST_PURCHASE_DRAFT, obj)
        .then((result) => {
          const { code, data, text } = result;
          if (code !== "200") {
            this.alert(text);
            return;
          }

          this.detailData.refPk = data.draftCid;
          this.$refs.PdfConverter.generateReport();
        });
    },

    async onClickAnnounceMove() {
      const result = await ApiService.shared.getData(this.$apiPath.CREATE_ANNOUNCE_NUMBER);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      const { requestCid } = this.model;
      const obj = {
        type: "purchase",
        requestCid,
      };
      await this.$router.push({
        path: `${this.$routerPath.ANNOUNCE_WRITE}/${data.postCid}`,
        query: obj,
      });
    },

    async onClickOrderMove() {
      const result = await ApiService.shared.getData(this.$apiPath.CREATE_ORDER_NUMBER);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      const { requestCid } = this.model;
      const obj = {
        type: "purchase",
        requestCid,
      };
      await this.$router.push({
        path: `${this.$routerPath.ORDER_WRITE}/${data.orderCid}`,
        query: obj,
      });
    },
    onClickRenewalPurchase() {
      const params = {
        text: "재구매 요청을 하시겠습니까?<br />재구매 요청은 바로 작성 하셔야됩니다.",
        onClickY: () => {
          this.$router.push({
            name: `${this.$routerPath.PURCHASE_WRITE}`,
            params: { requestCid: `${this.model.requestCid}`, type: "renewal" },
          });
        },
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
  },
};
</script>
